@import breakpoints

//Layout Standard Width
$width: 100%
$standard_max_width: 110rem

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Header bar height
$header_height: 7.5rem

// Margins
$section_margin: 7.5rem

// Primary Colors | Changable
$rose: #ddcabe
$angel: #ede3d9
$red: #b53612
$green: green

// Not that changable
$black: #120700
$white: #f7f1ea
$dark_grey: #7E7E7E

// Fonts
@font-face
    font-weight: 400
    font-family: 'cofley'
    src: url('../fonts/cofley/cofley-thin.ttf') format("truetype")

@font-face
    font-weight: 300
    font-family: 'cofley'
    src: url('../fonts/cofley/cofley-light.ttf') format("truetype")

$font-main: 'cofley'
$font-title: 'cofley'
