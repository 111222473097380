.quantity
    $input_size: 5.5rem
    $button_size: calc(#{$input_size} / 2)
    $quantity_width: calc(#{$input_size} + (#{$input_size} / 2))
    $quantity_height: $input_size
    min-width: $quantity_width
    width: auto
    height: $quantity_height

    background-color: $white

    display: flex
    justify-content: flex-start
    align-items: center


    > input[type=number]
        -webkit-appearance: textfield
        width: $input_size
        height: $input_size
        text-align: center
        outline: none
        border: 1px solid $black
        background-color: $white
        user-select: none

    &__button
        width: $button_size
        height: $button_size
        border: 1px solid $black
        border-left: none

        display: flex
        justify-content: center
        align-items: center

        cursor: pointer

        padding: 30%

        &:nth-child(1)
            border-bottom: none

        > img
            user-select: none