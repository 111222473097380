.font
    font-family: $font-title
    text-transform: uppercase

    &__title
        font-size: 2.5rem

    &__subtitle
        font-size: 2.25rem

        &--big
            font-size: 2.5rem

            @include sm
                font-size: 2.75rem


    &__paragraph
        font-family: $font-main
        font-weight: 400
        font-size: 1.6rem

.hideOverText
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

