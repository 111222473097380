textarea
    $padding: 1.5rem

    width: 30rem
    height: 10rem
    font-family: $font-main
    font-size: 1.6rem
    padding: $padding
    outline: none
    border: 1px solid $black
    background-color: $white

    resize: vertical

    &:focus
        border: .1rem solid $red

    &--testSize
        height: 300px
        width: 400px
